import React, { useState } from "react";
import { createGlobalStyle, styled, Block } from "../../seenspire-library";
import AuthBackgroundFrame from "../../assets/auth-background-video-frame.jpg";

export const AppBackgroundColor = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background.base} !important;
  }
`;

export const AuthAppBackgroundColor = createGlobalStyle`
  body {
    background-color: #0a0e17!important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
`;

export const WrapOverflow = styled(Block)<{ maxWidth?: any }>`
  max-width: ${({ maxWidth }) => maxWidth || "90%"};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
`;

// background video

const AuthAppBackgroundVideo = styled.video<{ loaded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -3;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  will-change: opacity;
`;

export const AuthAppBackgroundOverlay = styled(Block)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
`;

export const AuthAppBackgroundDots = styled(Block)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.4) 10%,
      transparent 10%
    ),
    radial-gradient(circle, rgba(255, 255, 255, 0.4) 10%, transparent 10%);
  background-size: 15px 15px;
  animation: moveDots 10s linear infinite;
  z-index: -2;
`;

export const AuthAppBackgroundFrameImage = styled.img`
  position: absolute;
  top: 0;
  left: 0,
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -3;
`;

export const AuthAppBackground = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <AuthAppBackgroundOverlay />
      <AuthAppBackgroundDots />
      {!isLoaded && (
        <AuthAppBackgroundFrameImage
          src={AuthBackgroundFrame}
          alt="Background Frame"
        />
      )}
      <AuthAppBackgroundVideo
        autoPlay
        muted
        loop
        playsInline
        preload="metadata"
        aria-hidden="true"
        role="presentation"
        onCanPlay={() => setIsLoaded(true)}
        onError={(e) => {
          console.error("Error loading background video --->", e);
          setIsLoaded(false);
        }}
        loaded={true}
      >
        <source
          src="https://storage.googleapis.com/production-seenspire-webapp/static/media/seenspire-video-background-converted.mp4"
          type="video/mp4"
        />
      </AuthAppBackgroundVideo>
    </>
  );
};
