import React, { FunctionComponent, useState } from "react";
import { IRouted, getParams } from "../../../routes";
import {
  Block,
  styled,
  Spacer,
  Panel,
  Media,
  Icon,
  hsla,
} from "../../../../seenspire-library";
import { analytics } from "../../../backend/firebase";
import { AppLink } from "../../../components/LinkButton/LinkButton";
import { Formik, FormikHelpers, Form, Field } from "formik";
import { ILoginData } from "../../../interfaces";
import {
  onFail,
  formStatus,
} from "../../../components/FormCtaButton/FormCtaButton";
import * as Yup from "yup";
import InputField from "../../../components/InputField/InputField";
import { FormModalButtons } from "../../../components/ModalButtons/ModalButtons";
import { Trans } from "react-i18next";
import { AuthRoutNames } from "../../../routes/AuthRoutes";
import { login } from "../../../backend/oauth";
import { isPartner } from "../../../util/AccountUtil";
import { AuthAppBackgroundColor, AuthAppBackground } from "../../../styles";
import { chain, size } from "lodash";
import { Account } from "seenspire-api-client";

const StyledLink = styled.a`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray.lighter};
`;

export const AuthLayout = styled(Block)`
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 600px;
  ${Media.at.small`
    grid-template-columns: 1fr;
    padding: 15px;
  `}
`;

export const AuthContainer = styled(Block)`
  border-bottom: solid 5px transparent;
`;

export const AuthText = styled(Block)<{ skipMarginBottom?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.gray.darker};
  line-height: 24px;
  text-align: center;
  &:not(:last-child) {
    margin-bottom: ${({ skipMarginBottom }) => !skipMarginBottom && "5px"};
  }
`;

export const AuthTitle = styled(Block)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.gray.darkest};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 32px;
  letter-spacing: -0.8px;
  line-height: 42px;
  text-align: center;
`;

export const RegisterTitle = styled(AuthTitle)`
  text-align: left;
`;

export const AuthLogoImg = styled(Block).attrs({})<{ urlImg: string }>`
  background-image: url(${({ urlImg }) => urlImg});
  background-repeat: no-repeat;
  height: 50px;
  max-width: 190px;
  margin: -70px auto 20px auto;
  background-size: contain;
  background-position: center;
  img {
    max-height: 65px;
    width: 100%;
  }
`;

export const AuthFooterStyled = styled(Block)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.thin};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colors.gray.lighter};
  padding: 20px;
  text-align: center;
`;

export const WarningPanel = styled(Panel)`
  border: 1px solid ${({ theme }) => theme.colors.alert.base};
  background-color: ${({ theme }) => hsla(theme.colors.alert.base, 0.05)};
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 18px;
`;

export const AuthFooter = () => {
  return (
    <AuthFooterStyled>
      <Trans i18nKey="auth.by_using_our_services_agree_to_cookies">
        <span>By using our services, you agree to our </span>
        <StyledLink
          href="https://www.seenspire.com/policies/privacy-policy"
          target="_blank"
        >
          {" "}
          Privacy policy
        </StyledLink>
        <span>and</span>
        <StyledLink
          href="https://www.seenspire.com/policies/cookie-policy"
          target="_blank"
        >
          {" "}
          Cookie policy
        </StyledLink>
        .
      </Trans>
    </AuthFooterStyled>
  );
};

export const AuthPanel = styled(Block)`
  background-color: ${({ theme }) => theme.colors.white.base};
  border-radius: 10px;
  padding: 0;
  box-shadow: 0 1px 2px 0
    ${({ theme }) => hsla(theme.colors.gray.darkest, 0.18)};
  display: grid;
  grid-gap: 20px;
  min-height: 250px;
`;

export const LoginAuthPanel = styled(AuthPanel)`
  grid-gap: unset;
  padding: 70px;
`;

const EmptyLoginData = {
  email: "",
  password: "",
  persistSession: false,
};

const Login: FunctionComponent<IRouted> = ({
  client,
  partnerSettings: settings,
  t,
  slug,
  redirect,
  fetchUser,
  history,
}) => {
  const [params] = useState(getParams());
  const [data] = useState<ILoginData>(EmptyLoginData);
  const redirectPathname =
    params?.redirect ??
    (AuthRoutNames.includes(redirect.route.name as any)
      ? `/${slug}/dashboard`
      : redirect.pathname);

  /** @ts-ignore */
  if (settings && settings.account && settings.account.workosredirect) {
    /** @ts-ignore */
    window.location = `${process.env.REACT_APP_API}${settings.account.workosredirect}`;
  }

  const onSubmit = async (
    values: ILoginData,
    actions: FormikHelpers<ILoginData>
  ) => {
    console.log(`[LOGIN] logging in ...`);
    actions.setStatus({});
    try {
      await login(values);

      const _user = await fetchUser();

      // Skip login if the user belong to one account, and that account is disabled
      const accountDisabled =
        size(_user.account_users) === 1 &&
        !chain(_user.account_users)
          .filter((userAccount) => userAccount.account.disabled)
          .isEmpty()
          .value();
      if (accountDisabled) {
        onFail(actions, {}, () => {}, "Account disabled!");
        return;
      }

      // Skip login if user belong to on account, and that account is expired
      const accountExpired =
        size(_user.account_users) === 1 &&
        !chain(_user.account_users)
          .filter(
            (userAccount) =>
              userAccount.account.status.replace("_", "") ===
              Account.StatusEnum.TRIALEXPIRED
          )
          .isEmpty()
          .value();

      if (accountExpired) {
        onFail(actions, {}, () => {}, "Account expired!");
        return;
      }

      const accountToSwitchTo = !_user.last_used_account
        ? _user.account_users[0].account.id
        : _user.last_used_account;

      await client.Users.postSwitchaccount({
        account_id: accountToSwitchTo,
      });

      console.log(`[LOGIN] user logged in, redirecting to ${redirectPathname}`);

      //Zoho pagesense adds a real name instead of random
      if (_user && _user.first_name && _user.last_name) {
        const fullName = `${_user.first_name} ${_user.last_name}`;
        (window as any).pagesense = (window as any).pagesense || [];
        (window as any).pagesense.push(["identifyUser", fullName]);
      }

      // Google Analytics
      if (analytics) {
        analytics.logEvent("login", {
          account_id: _user.account_users[0].account.id,
          organization_name:
            _user.account_users[0].account.organization_info.name,
          user_name: `${_user.first_name} ${_user.last_name}`,
        });
      }

      if ((window as any).pendo) {
        (window as any).pendo.initialize({
          account: {
            id: _user.account_users[0].account.id,
            full_name: `${_user.first_name} ${_user.last_name}`,
          },
        });
      }

      history.push(redirectPathname, { reAuthenticate: true });
    } catch (error) {
      console.log(error);
      onFail(actions, error);
    }
  };

  if (!data) return null;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("auth.email_is_invalid"))
      .required(t("auth.email_is_required")),
    password: Yup.string().required(t("auth.password_is_required")),
    persistSession: Yup.boolean(),
  });

  // hack to get the dark logo
  const lightModeLogoUrl =
    "https://static.seenspire.com/v2/frontend/logo_light.svg";
  const darkModeLogoUrl =
    "https://static.seenspire.com/v2/frontend/logo_dark.svg";
  console.log(params);
  return (
    <>
      {/** @ts-ignore*/}
      {settings && settings.account && !settings.account.workosredirect && (
        <>
          <AuthAppBackgroundColor />
          <AuthAppBackground />
          <AuthLayout>
            <AuthContainer>
              <AuthLogoImg
                urlImg={
                  params.ssc && settings.login_logo === darkModeLogoUrl
                    ? lightModeLogoUrl
                    : settings.login_logo
                }
              />
              <LoginAuthPanel>
                <AuthTitle>{t("Log in to your account")}</AuthTitle>
                <Block padding={{ top: 16, bottom: 24 }}>
                  <AuthText skipMarginBottom>
                    {t("auth.enter_your_login_details")}
                  </AuthText>
                </Block>
                <Formik
                  initialValues={data}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {(form) => (
                    <Form>
                      <Block display="grid" gridGap={20}>
                        <Field
                          name="email"
                          type="email"
                          title={t("Business email")}
                          component={InputField}
                        />
                        <Field
                          name="password"
                          type="password"
                          title={t("Password")}
                          component={InputField}
                        />
                        <Block display="flex">
                          <Block>
                            <Field
                              name="persistSession"
                              type="checkbox"
                              fontWeight="normal"
                              title={t("Keep me logged in")}
                              component={InputField}
                            />
                          </Block>
                          <Spacer></Spacer>
                          <AppLink to="reset-password">
                            <Block
                              textDecoration="underline"
                              margin={{ left: 10 }}
                              color="gray"
                            >
                              {t("Forgot your password?")}
                            </Block>
                          </AppLink>
                        </Block>
                        {form.status &&
                          form.status.status === formStatus.failed && (
                            <WarningPanel
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Icon name="error" margin={{ right: 10 }} />
                              {form.status.error || t("generals.error_title")}
                            </WarningPanel>
                          )}
                        <FormModalButtons
                          form={form}
                          submit={t("auth.sign_in")}
                        />

                        {isPartner(settings) && (
                          <AuthText display="flex" justifyContent="center">
                            {t("Don't have an account yet?")}
                            <AppLink to="signup">
                              <Block margin={{ left: 10 }} color="cta">
                                {t("auth.sign_up_here")}
                              </Block>
                            </AppLink>
                          </AuthText>
                        )}
                      </Block>
                    </Form>
                  )}
                </Formik>
              </LoginAuthPanel>
            </AuthContainer>
            <AuthFooter />
          </AuthLayout>
        </>
      )}
    </>
  );
};

export { Login };
