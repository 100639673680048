import React, { FunctionComponent, useState, useEffect } from "react";
import { IRouted } from "../../../routes";
import {
  Block,
  styled,
  Spacer,
  Panel,
  Media,
  hsla,
  TransitionBase,
  renderShadow,
  Button,
  Icon,
} from "../../../../seenspire-library";
import { AppLink } from "../../../components/LinkButton/LinkButton";
import { Formik, FormikHelpers, Form, Field } from "formik";
import { testSequence } from "./helpers/sequence";
import backend from "../../../backend";
import {
  onSuccess,
  onFail,
  formStatus,
} from "../../../components/FormCtaButton/FormCtaButton";
import * as Yup from "yup";
import InputField from "../../../components/InputField/InputField";
import { FieldWithTick, FieldWithSecondTick } from "../../Profile/Profile";
import {
  FormModalButtons,
  StyledButton,
} from "../../../components/ModalButtons/ModalButtons";
import { useTranslation, Trans } from "react-i18next";
import { UserRegistration, Invite3 } from "seenspire-api-client";
import {
  AuthLayout,
  AuthContainer,
  AuthTitle,
  AuthPanel,
  AuthText,
  AuthLogoImg,
  AuthFooter,
  WarningPanel,
  RegisterTitle,
} from "../Login/Login";
import { omit, get, has } from "lodash";
import { storeToken } from "../../../backend/session";
import { AccountTypeEnum } from "../../../interfaces";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { isPartner } from "../../../util/AccountUtil";
import { AuthAppBackground, AuthAppBackgroundColor } from "../../../styles";
import { TransWithStrong } from "../../../components/TransText/TransText";
import ReCAPTCHA from "react-google-recaptcha";
import { analytics } from "../../../backend/firebase";

interface IUserRegistration extends UserRegistration {
  acceptTerms: boolean;
  company_name: string;
  number_of_licences: number;
  account: number;
  security_key: string | undefined;
}

const userTag = `${Math.floor(Math.random() * 10000)}`;
const _EmptyRegisterData: IUserRegistration = {
  avatar: "",
  first_name: userTag,
  last_name: userTag,
  email: `mahmoud-${userTag}@alazzawi.co`,
  // email: ``,
  plain_password: {
    first: "antwerpen2",
    second: "antwerpen2",
  },
  company_name: "_",
  number_of_licences: 0,
  account: 0,
  acceptTerms: false,
  confirmed: false,
  security_key: undefined,
};
const EmptyRegisterData: IUserRegistration = {
  avatar: "",
  first_name: "",
  last_name: "",
  email: "",
  plain_password: {
    first: "",
    second: "",
  },
  company_name: "_",
  number_of_licences: 0,
  account: 0,
  acceptTerms: false,
  confirmed: false,
  security_key: undefined,
};

const StyledLink = styled.a`
  font-weight: 600;
  color: #0040e5;
`;

const RightInfoList = styled.ul`
  margin: 0 0 0 0;
  list-style-type: none;
  padding: 30px;
`;

const RightInfoListItem = styled.li`
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  font-size: 16px;
  color: #3a404b;
`;

const PreTextInfo = styled.span`
  font-size: 16px;
  color: #767a82;
  margin-bottom: 78px;
`;

const TextInfo = styled(PreTextInfo)`
  margin-bottom: 10px;
`;

const CheckIcon = styled.span`
  float: left;
  margin: 5px 5px 0 0;
`;

enum RegistrationSteps {
  "INITIAL",
  "USER_INFO",
  "INVITATION_ERROR",
  "USER_STATUS_APPROVED",
  "USER_STATUS_PENDING",
  "COMPANY",
  "ERROR",
  "COMPLETED",
}

const BackButton = styled(Block)`
  color: ${({ theme }) => theme.colors.gray.base};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  letter-spacing: -0.2px;
  cursor: pointer;
`;

const SpacerStep1Register = styled.div`
  margin-bottom: 90px;
`;

const AuthLayoutWide = styled(AuthLayout)`
  grid-template-columns: 800px;
`;

const HidePasswordIcon = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

function checkIfPasswordError(error: any) {
  return get(error, "errorMessage.plain_password.message");
}

export const TermsAndConditionsText: FunctionComponent = () => {
  return (
    <Trans i18nKey="auth.i_agree_to_terms">
      <span>I agree to the</span>
      <StyledLink
        href="https://www.seenspire.com/terms-and-conditions"
        target="_blank"
      >
        {" "}
        terms and conditions{" "}
      </StyledLink>
      and
      <StyledLink
        href="https://www.seenspire.com/privacy-policy"
        target="_blank"
      >
        {" "}
        privacy policy{" "}
      </StyledLink>
    </Trans>
  );
};

const Register: FunctionComponent<
  IRouted<{ inviteId?: string; answer?: string }>
> = ({
  partnerSettings: settings,
  t,
  goTo,
  client,
  match,
  slug,
  fetchUser,
  logout,
  redirect,
}) => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState<RegistrationSteps>(
    RegistrationSteps.INITIAL
  );
  const [data, setData] = useState<IUserRegistration>({
    ...EmptyRegisterData,
    account: slug as any,
  });
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
  const [invitationData, setInvitationData] = useState<any>();
  const [invitationError, setInvitationError] = useState();
  const [inviteId] = useState<any>(match && match.params.inviteId);
  const [answer] = useState<any>(match && match.params.answer);
  const [companyNameInUse, setCompanyNameInUse] = useState<boolean>(false);
  const [acceptTermsError, setAcceptTermsError] = useState<string>("");
  const [recaptchaError, setRecaptchaError] = useState<string>("");
  const [passwordToggle, setPasswordToggle] = useState(false);

  useEffect(() => {
    //@TODO Change to a better check
    (settings as any).id !== 0 && init();
  }, [settings]);

  const init = async () => {
    if (!inviteId) {
      if (isPartner(settings)) {
        const emailToPreFill = redirect.query.get("email") || "";
        setData({ ...data, email: emailToPreFill || data.email });
        return setStep(RegistrationSteps.USER_INFO);
      } else {
        return logout();
      }
    } else {
      await loadInvitationData();
    }
  };

  const loadInvitationData = () => {
    if (answer === "decline") {
      return client.Invites.handleInvite(inviteId, "decline").then(() => {
        goTo("login", true);
      });
    }
    setStep(RegistrationSteps.INITIAL);
    client.OpenInvites.handleInvite(inviteId, "accept")
      .then((_invitationData: any) => {
        if (_invitationData.access_token) {
          storeToken({ data: _invitationData });
          setStep(RegistrationSteps.USER_STATUS_APPROVED);
          return;
        }
        if (_invitationData.status === "APPROVED") {
          setStep(RegistrationSteps.USER_STATUS_APPROVED);
        } else {
          setStep(RegistrationSteps.USER_STATUS_PENDING);
        }
        setInvitationData(_invitationData);
        setData({
          ...EmptyRegisterData,
          account: _invitationData.account.id,
          email: _invitationData.email,
          acceptTerms: false,
          company_name: slug,
        });
      })
      .catch((error: any) => {
        setInvitationError(error.statusText);
        setStep(RegistrationSteps.INVITATION_ERROR);
      });
  };
  const onSubmit = async (
    values: any,
    actions: FormikHelpers<IUserRegistration>
  ) => {
    if (!invitationData && !values.security_key) {
      actions.setStatus({});
      setRecaptchaError(
        "To continue, please accept the ReCaptcha check above."
      );
      actions.setSubmitting(false);
      return;
    }
    const _values: any = omit(values, ["acceptTerms", "confirmed", "avatar"]); // UserRegistration
    actions.setStatus({});
    setAcceptTermsError("");
    setRecaptchaError("");
    setCompanyNameInUse(false);

    _values.plain_password.second = _values.plain_password.first;

    try {
      let token = invitationData
        ? await client.OpenUsers.postInviteRegistration(_values, inviteId)
        : await client.OpenUsers.postRegistration(_values);

      // Issue in the backend require to check on error within response of 200
      // only for postInviteRegistration
      if (checkIfPasswordError(token)) {
        onFail(actions, {}, () => {}, checkIfPasswordError(token));
        setPasswordsMatch(false);
        return;
      }

      storeToken({ data: token });
      const _user = await fetchUser();

      // Google Analytics
      if (analytics) {
        analytics.logEvent("register", {
          account_id: _user.account_users[0].account.id,
          organization_name:
            _user.account_users[0].account.organization_info.name,
          user_name: `${_user.first_name} ${_user.last_name}`,
        });
      }

      const targetAccountSlug =
        _user.account_users[0].account.organization_info.slug;
      window.history.pushState(
        undefined,
        "",
        `/${targetAccountSlug}/dashboard`
      );
      window.location.reload();
    } catch (error) {
      const _emailInUseError = get(
        error,
        "response.data.errorMessage.email.message"
      );
      const _companyNameInUse = get(
        error,
        "response.data.errorMessage.company_name.message"
      );
      const _errorInPassword = get(
        error,
        "response.data.errorMessage.plain_password.message"
      );

      if (_emailInUseError) {
        setStep(RegistrationSteps.USER_INFO);
        actions.setFieldError("email", t("auth.email_already_in_use"));
        actions.setSubmitting(false);
        return;
      }

      if (_errorInPassword) {
        setStep(RegistrationSteps.USER_INFO);
        onFail(actions, {}, () => {}, _errorInPassword);
        setPasswordsMatch(false);
        actions.setSubmitting(false);
        return;
      }

      if (_companyNameInUse) {
        setCompanyNameInUse(true);
        actions.setSubmitting(false);
        return;
      }

      setStep(RegistrationSteps.ERROR);
      onFail(actions, error);
    }
  };

  if (!data) return null;
  const notAllowed: string[] = []; // ["@gmail.com", "@yahoo.com", "@hotmail.com"];
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("auth.email_is_invalid"))
      .required("")
      .test(
        "no-personal-email",
        t("auth.user_work_email_notice"),
        (value) =>
          !notAllowed.some((mail) =>
            value ? value.toLowerCase().includes(mail) : false
          )
      ),
    first_name: Yup.string().required("Please enter your first name"),
    last_name: Yup.string().required("Please enter your last name"),
    company_name: Yup.string().required("Please enter your company name"),
    account: Yup.string(),
    number_of_licences: Yup.string(),
    acceptTerms: Yup.boolean(),
    security_key: Yup.string(),
    plain_password: Yup.object().shape({
      first: Yup.string()
        .required("Password is required")
        .min(8, t("auth.reset_email_click_password_validation"))
        .matches(/\d+/, t("auth.password_validation"))
        .matches(/[a-z]/, t("auth.password_validation"))
        .matches(/[A-Z]/, t("auth.password_validation"))
        .matches(/[^a-zA-Z0-9]/, t("auth.password_validation"))
        .test(
          "sequence",
          "Password cannot contain 3 or more sequential characters",
          (value) => {
            if (!value) return false;
            return !testSequence(value);
          }
        ),
      /*second: Yup.string()
        .oneOf([Yup.ref('first'), ''], t('auth.password_unmatch'))
        .required('Password confirm is required.')*/
    }),
  });

  const checkPasswords = (values: any) => {
    const { plain_password } = values;

    if (!plain_password) return false;

    const { first, second } = plain_password;

    const _passwordsMatch =
      first &&
      first.length >= 8 &&
      first.match(/\d+/) &&
      first.match(/[a-z]/) &&
      first.match(/[A-Z]/) &&
      first.match(/[^a-zA-Z0-9]/) &&
      !testSequence(first)
        ? //first === second
          true
        : false;

    setPasswordsMatch(_passwordsMatch);

    return _passwordsMatch;
  };

  const liscensesOptions = [
    { label: "1 - 10", value: "1" },
    { label: "11 - 50", value: "2" },
    { label: "51 - 100", value: "3" },
    { label: "more than 100", value: "4" },
  ];

  return (
    <AuthLayoutWide>
      <AuthAppBackgroundColor />
      <AuthAppBackground />
      <AuthContainer>
        <AuthLogoImg urlImg={settings.login_logo} />
        <AuthPanel>
          {step === RegistrationSteps.INITIAL && (
            <Block
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={{ left: 40, right: 40, top: 40, bottom: 40 }}
            >
              <Block display="grid" justifyContent="center">
                <Icon iconSize="large" name="loading" strokeColor="primary" />
              </Block>
            </Block>
          )}

          {step === RegistrationSteps.INVITATION_ERROR && (
            <Block
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={{ left: 40, right: 40, top: 40, bottom: 40 }}
            >
              <Block display="grid" gridGap={20}>
                <Block display="grid" justifyContent="center">
                  <Icon iconSize="larger" name="error" />
                </Block>
                <AuthTitle>{t("Invitation error")}</AuthTitle>
                <AuthText>
                  {t(
                    "It seems there is an error with this invitation, please try again, otherwise inform your contact person!"
                  )}
                </AuthText>
                {invitationError && (
                  <WarningPanel>
                    <AuthText>{invitationError}</AuthText>
                  </WarningPanel>
                )}
                <StyledButton
                  elementWidth="block"
                  type="button"
                  buttonType="accent"
                  onClick={() => {
                    loadInvitationData();
                  }}
                >
                  {t("Try again")}
                </StyledButton>
              </Block>
            </Block>
          )}

          {step === RegistrationSteps.USER_STATUS_APPROVED && !invitationData && (
            <Block
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={{ left: 40, right: 40, top: 40, bottom: 40 }}
            >
              <Block display="grid" gridGap={20}>
                <Block display="grid" justifyContent="center">
                  <Icon iconSize="larger" name="checkGreen" />
                </Block>
                <AuthTitle>{t("Invitation accepted ")}</AuthTitle>
                <AuthText>
                  {t("Your invitation has been accepted successfully!")}
                </AuthText>
                <StyledButton
                  elementWidth="block"
                  type="button"
                  buttonType="cta"
                  onClick={() => {
                    goTo("account/home", true);
                  }}
                >
                  {t("Continue")}
                </StyledButton>
              </Block>
            </Block>
          )}

          {step === RegistrationSteps.USER_STATUS_APPROVED && invitationData && (
            <Block
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin={{ left: 50, right: 50, top: 50, bottom: 50 }}
            >
              <Block display="grid" gridGap={20}>
                <Block display="grid" justifyContent="center">
                  <Icon iconSize="larger" name="checkGreen" />
                </Block>
                <AuthTitle>{t("Invitation accepted ")}</AuthTitle>
                {!!invitationData && (
                  <AuthText>
                    {t("Your invitation to ")}
                    {invitationData.account.organization_info.name}{" "}
                    {t(" has been accepted successfully!")}
                  </AuthText>
                )}
                <StyledButton
                  elementWidth="block"
                  type="button"
                  buttonType="cta"
                  onClick={() => {
                    goTo("login", true);
                  }}
                >
                  {t("Login")}
                </StyledButton>
              </Block>
            </Block>
          )}

          {step === RegistrationSteps.COMPLETED && (
            <Block
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={{ left: 40, right: 40, top: 40, bottom: 40 }}
            >
              <Block display="grid" gridGap={20}>
                <Block display="grid" justifyContent="center">
                  <Icon iconSize="larger" name="mail" />
                </Block>
                <AuthTitle>{t("Confirm your email ..")}</AuthTitle>
                <AuthText>
                  {t(
                    "In a moment, you should receive and email from us, to activate your account please confirm your email!"
                  )}
                </AuthText>
              </Block>
            </Block>
          )}
          {console.log(step)}
          {step !== RegistrationSteps.COMPLETED &&
            step !== RegistrationSteps.INVITATION_ERROR &&
            step !== RegistrationSteps.INITIAL &&
            step !== RegistrationSteps.USER_STATUS_APPROVED && (
              <Formik
                initialValues={data}
                validationSchema={validationSchema}
                validate={(values: any) => {
                  checkPasswords(values);
                  let hasError = {};
                  if (values.acceptTerms) {
                    setAcceptTermsError("");
                  } else {
                    setAcceptTermsError(
                      "To continue, please accept the terms and conditions above."
                    );
                    hasError = {
                      ...hasError,
                      acceptTerms:
                        "To continue, please accept the terms and conditions above.",
                    };
                  }
                  if (values.security_key) {
                    setRecaptchaError("");
                  } else {
                    setRecaptchaError(
                      "To continue, please accept the ReCaptcha check above."
                    );
                    hasError = {
                      ...hasError,
                      security_key: true,
                    };
                  }

                  return hasError;
                }}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {(form) => {
                  return (
                    <Form>
                      <div
                        style={{
                          display: "flex",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "calc(100% - 270px)",
                            padding: "70px",
                            position: "relative",
                          }}
                        >
                          <Block display="grid" gridGap={20}>
                            {(step === RegistrationSteps.USER_INFO ||
                              step ===
                                RegistrationSteps.USER_STATUS_PENDING) && (
                              <Block display="grid" gridGap={20}>
                                {step ===
                                RegistrationSteps.USER_STATUS_PENDING ? (
                                  <AuthTitle>
                                    {t("Complete your registration")}
                                  </AuthTitle>
                                ) : (
                                  <RegisterTitle>
                                    {t("Welcome to Seenspire")}
                                  </RegisterTitle>
                                )}
                                <PreTextInfo>
                                  Let's get started with a few simple steps
                                </PreTextInfo>
                                <Field
                                  name="email"
                                  disabled={!!invitationData}
                                  type="email"
                                  title={t("Enter you business email")}
                                  component={InputField}
                                />
                              </Block>
                            )}

                            {step === RegistrationSteps.COMPANY && (
                              <Block display="grid" gridGap={20}>
                                <RegisterTitle>
                                  {t("Set up your account")}
                                </RegisterTitle>
                                <TextInfo>
                                  Fill in your profile details
                                </TextInfo>

                                <Block
                                  display="grid"
                                  direction={{ small: "row", medium: "column" }}
                                  gridGap={20}
                                >
                                  <Field
                                    name="first_name"
                                    type="text"
                                    title={t("First name")}
                                    component={InputField}
                                  />
                                  <Field
                                    name="last_name"
                                    type="text"
                                    title={t("Last name")}
                                    component={InputField}
                                  />
                                </Block>

                                {!inviteId && (
                                  <Field
                                    name="company_name"
                                    type="text"
                                    title={t("Company name")}
                                    component={InputField}
                                  />
                                )}

                                <FieldWithSecondTick showTick={passwordsMatch}>
                                  <Field
                                    name="plain_password.first"
                                    type={passwordToggle ? "text" : "password"}
                                    title={t("Password")}
                                    component={InputField}
                                  />

                                  <HidePasswordIcon
                                    onClick={() => {
                                      setPasswordToggle(!passwordToggle);
                                    }}
                                  >
                                    {passwordToggle ? (
                                      <Icon
                                        name="eyeFilled"
                                        iconFill="#6c6c6c"
                                      />
                                    ) : (
                                      <Icon
                                        name="eyeInvisibleFilled"
                                        iconFill="#b3b3b3"
                                      />
                                    )}
                                  </HidePasswordIcon>
                                </FieldWithSecondTick>

                                {/*<Field name="number_of_licences" type="select"
                                  options={liscensesOptions} isValuePrimitive
                            title={t('auth.number_of_screens')} component={InputField} />*/}

                                {/*<FieldWithTick showTick={passwordsMatch}>
                                  <Field name="plain_password.second" type="password" title={t('auth.confirm_password')} component={InputField} />
                            </FieldWithTick>*/}

                                <Field
                                  name="acceptTerms"
                                  type="checkbox"
                                  title={<TermsAndConditionsText />}
                                  fontWeight="normal"
                                  component={InputField}
                                />

                                {/* @TODO Handle error or expired status */}
                                <ReCAPTCHA
                                  size="normal"
                                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                  onChange={(data) => {
                                    if (data) {
                                      form.setFieldValue("security_key", data);
                                    }
                                  }}
                                />

                                {companyNameInUse && (
                                  <WarningPanel>
                                    <Block display="grid" gridGap={15}>
                                      {companyNameInUse && (
                                        <Block>
                                          <Trans i18nKey="auth.company_name_already_in_use">
                                            <span>
                                              The company name is already in
                                              use, please choose a different
                                              company name.
                                            </span>
                                            <br />
                                            <span>
                                              If you or one of your team members
                                              has not created an account with
                                              Seenspire, please
                                            </span>
                                            <StyledLink
                                              href="https://help.seenspire.com/portal/newticket"
                                              target="_blank"
                                            >
                                              {" "}
                                              contact us{" "}
                                            </StyledLink>
                                            <span>
                                              and we will try to help you get
                                              started.
                                            </span>
                                          </Trans>
                                        </Block>
                                      )}
                                    </Block>
                                  </WarningPanel>
                                )}

                                {form.submitCount > 0 && recaptchaError && (
                                  <WarningPanel>
                                    <Block>{recaptchaError}</Block>
                                  </WarningPanel>
                                )}

                                <StyledButton
                                  elementWidth="block"
                                  type="submit"
                                  buttonType="cta"
                                >
                                  {form.isSubmitting ? (
                                    <Icon name="loading" strokeColor="white" />
                                  ) : !invitationData ? (
                                    t("Start your free trial")
                                  ) : (
                                    t("Get started")
                                  )}
                                </StyledButton>
                              </Block>
                            )}

                            {form.status &&
                              form.status.status === formStatus.failed && (
                                <WarningPanel>
                                  {form.status.error ||
                                    Object.values(form.errors).map(
                                      (errorValue, index) => (
                                        <Block key={index}>{errorValue}</Block>
                                      )
                                    )}
                                </WarningPanel>
                              )}

                            {step === RegistrationSteps.USER_INFO &&
                              !invitationData && (
                                <>
                                  <StyledButton
                                    elementHeightBase={false}
                                    elementWidth="block"
                                    type="button"
                                    buttonType="cta"
                                    onClick={async () => {
                                      let emailValid = false;
                                      try {
                                        // @ts-ignore-next-line
                                        await client.OpenUsers.postPreRegistration(
                                          {
                                            email: form.values.email,
                                            slug: slug,
                                          }
                                        );
                                        emailValid = true;
                                      } catch {
                                        console.log("No valid email");
                                      }

                                      if (emailValid) {
                                        // form.values.company_name === '_' && form.setFieldValue('company_name', `Account ${userTag}`);
                                        form.values.company_name === "_" &&
                                          form.setFieldValue(
                                            "company_name",
                                            ""
                                          );
                                        setStep(RegistrationSteps.COMPANY);
                                        setCompanyNameInUse(false);
                                      }
                                    }}
                                  >
                                    {t("Continue")}
                                  </StyledButton>
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      bottom: "0",
                                      left: "0",
                                      padding: "70px",
                                    }}
                                  >
                                    <Block>
                                      <AuthText
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        {t("auth.already_have_an_account")}
                                        <AppLink to="login">
                                          <Block
                                            margin={{ left: 10 }}
                                            color="cta"
                                          >
                                            {t("auth.sign_in_here")}
                                          </Block>
                                        </AppLink>
                                      </AuthText>
                                    </Block>
                                  </div>
                                </>
                              )}

                            {(step === RegistrationSteps.USER_INFO ||
                              step === RegistrationSteps.USER_STATUS_PENDING) &&
                              invitationData && (
                                <StyledButton
                                  elementHeightBase={false}
                                  elementWidth="block"
                                  type="button"
                                  buttonType="cta"
                                  onClick={async () => {
                                    let emailValid = false;
                                    try {
                                      // @ts-ignore-next-line
                                      await client.OpenUsers.postPreRegistration(
                                        {
                                          email: form.values.email,
                                          slug: slug,
                                        }
                                      );
                                      emailValid = true;
                                    } catch {
                                      console.log("No valid email");
                                    }

                                    if (emailValid) {
                                      // form.values.company_name === '_' && form.setFieldValue('company_name', `Account ${userTag}`);
                                      form.values.company_name === "_" &&
                                        form.setFieldValue("company_name", "");
                                      setStep(RegistrationSteps.COMPANY);
                                      setCompanyNameInUse(false);
                                    }
                                  }}
                                >
                                  {form.isSubmitting ? (
                                    <Icon name="loading" strokeColor="white" />
                                  ) : step ===
                                    RegistrationSteps.USER_STATUS_PENDING ? (
                                    t("Continue")
                                  ) : (
                                    t("Sign up")
                                  )}
                                </StyledButton>
                              )}
                          </Block>
                        </div>

                        {(step === RegistrationSteps.USER_INFO ||
                          step === RegistrationSteps.USER_STATUS_PENDING ||
                          step === RegistrationSteps.COMPANY) && (
                          <div
                            style={{
                              width: "270px",
                              backgroundColor: "#fafafa",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            <div
                              style={{
                                height: "377px",
                                backgroundColor: "black",
                                borderTopRightRadius: "8px",
                                transition: "background-image 0.5s ease-in-out",
                                backgroundImage:
                                  step === RegistrationSteps.USER_INFO
                                    ? "url(https://static.seenspire.com/assets/portals/img/signup_image_step1.jpg)"
                                    : "url(https://static.seenspire.com/assets/portals/img/signup_image_step2.jpg)",
                              }}
                            ></div>

                            <div
                              style={{
                                height: "calc(100% - 377px)",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RightInfoList>
                                <RightInfoListItem>
                                  <CheckIcon>
                                    <Icon
                                      name="checkDark"
                                      strokeColor="black"
                                    />
                                  </CheckIcon>{" "}
                                  One subscription
                                </RightInfoListItem>
                                <RightInfoListItem>
                                  <CheckIcon>
                                    <Icon
                                      name="checkDark"
                                      strokeColor="black"
                                    />
                                  </CheckIcon>{" "}
                                  Unlimited content
                                </RightInfoListItem>
                                <RightInfoListItem>
                                  <CheckIcon>
                                    <Icon
                                      name="checkDark"
                                      strokeColor="black"
                                    />
                                  </CheckIcon>{" "}
                                  No credit card required
                                </RightInfoListItem>
                              </RightInfoList>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
        </AuthPanel>
      </AuthContainer>
      <AuthFooter />
    </AuthLayoutWide>
  );
};

export { Register };
