import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import {
  styled,
  Block,
  unit,
  Media,
  Icon,
  Button,
  Text,
} from "../../../seenspire-library";
import { HeaderStyled } from "../Header/Header";
import { LinkButton, BackButton } from "../LinkButton/LinkButton";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../state";

import { map, isEmpty } from "lodash";
import {
  excludeSlugFromPathname,
  RouteConfig,
  getEntityId,
  getStreamEntityId,
  RoutesNames,
  canView,
  useCurrentRoute,
  RoutesConfig,
} from "../../routes";
import { AuthRoutNames } from "../../routes/AuthRoutes";
import { generateFeedRoutes } from "../../routes/FeedRoutes";
import { LAYOUT_MAX_WIDTH } from "../../containers/Dashboard/variables";
import useRouter from "use-react-router";
import { generateStreamRoutes } from "../../routes/StreamRoutes";
import useFirebaseAnon from "../../containers/AccountLicenceOverview/useFirebaseAnon";
import { useAccountInvoices } from "../../containers/AccountInvoices/useAccountInvoices";

const MenuStyledInner = styled(Block)`
  display: grid;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${unit(LAYOUT_MAX_WIDTH)};
  justify-content: center;
`;

const MenuItemsContainer = styled(Block)`
  display: flex;
  justify-content: center;
`;

const MenuStyledContainer = styled(HeaderStyled).attrs({})<{
  requireTopMargin: boolean;
}>`
  position: relative;
  z-index: ${({ theme }) => theme.header.zIndex - 1};
  top: unset;
  padding-top: ${({ theme, stickyNotificationIsActive }) =>
    stickyNotificationIsActive ? unit(theme.header.height * 2) : unit(60)};
  overflow: hidden;
  overflow-x: auto;
  justify-content: center;
  margin-top: ${({ theme, requireTopMargin }) =>
    requireTopMargin && unit(theme.header.height)};
  ${Media.lt.large`
    margin-top: ${(p: any) =>
      p.requireTopMargin && unit(p.theme.header.height - 10)};
  `}
  ${Media.lt.large`
    justify-content: flex-start;  
    padding-top: ${() => unit(90)};
    ${MenuStyledInner} {
      padding-right: 50px;
    }
  `}
  padding-bottom: 80px;
`;

const MenuItemSeparator = styled(Block)`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary.lightest};
  opacity: 0.5;
`;

const BackButtonLicenceOverview = styled(BackButton)`
  position: absolute;
  top: -20px;
`;

const LicenceOverviewInfoContainer = styled(Block)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const LicenceOverviewScreenCount = styled.span`
  color: #2bd65d;
`;

const MenuItem = styled(LinkButton)``;

const AppMenuCta = styled(Button)`
  max-height: 50px;
`;

const defaultBackButtonConfig = {
  label: "Go back",
  target: "dashboard",
};
const AppMenu: FunctionComponent = () => {
  const Context = useContext(AppContext);
  const {
    slug,
    user,
    currentRoute,
    account: { account },
    CtaConfig,
    setCtaConfig,
    client,
    appDisplayMode,
    activated,
    authenticated,
    stickyNotification: { active: stickyNotificationIsActive },
  } = Context;

  const { search } = useLocation();

  const { t } = useTranslation();
  const { location } = useRouter();
  const { pathname } = useLocation();

  const { data: deviceData, removeMonitor } = useFirebaseAnon(account);
  const [backButtonConfig, setBackButtonConfig] = useState<any>(
    defaultBackButtonConfig
  );
  const [menuItems, setMenuItems] = useState<RouteConfig[]>([]);

  useEffect(() => {
    if (account?.subscriptions?.length === 0) return;

    const isTrial =
      account?.subscriptions?.[0]?.plan?.slug === "free-30-day-trial";
    const _licences = isTrial ? 5 : account?.subscriptions?.[0].licences ?? 0;

    if (_licences > 0 && deviceData) {
      console.log("Device Data vs Licences", deviceData.length, _licences);
    }

    if (_licences > 0 && deviceData && deviceData.length > _licences) {
      const _devices = deviceData.slice(_licences);
      _devices.forEach((device) => {
        removeMonitor(device.uid);
      });
    }
  }, [user, deviceData]);

  const { invoices } = useAccountInvoices()

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    loadData();
  }, [
    currentRoute,
    location.key,
    activated,
    authenticated,
    account,
    deviceData,
    invoices,
  ]);

  const loadData = async () => {
    let _items: RouteConfig[] = [];
    currentRoute.skipSubMenu = false;

    if (currentRoute.routes) {
      _items = currentRoute.routes
        // .filter((route: RouteConfig) => {if (route.name === "account_licenceOverview") { return deviceData && deviceData.length > 0}; return true;})
        .filter((route: RouteConfig) => {
          if (route.name === "account_invoices") {
            return invoices && invoices.length > 0;
          }
          return true;
        })
        .filter((route: RouteConfig) => !["redirect"].includes(route.name))
        .filter((route) => !route.hideFromMenu)
        .filter((route) => canView(route, Context));
    }

    // Mutate path for certain routes
    if (
      currentRoute.name === "feed" &&
      getEntityId("feed", location.pathname)
    ) {
      const feed = (await client.ActiveChannels.getOpenActiveChannelById(
        getEntityId("feed", location.pathname)
      )) as any;
      if (feed.draft) {
        _items = [];
        currentRoute.skipSubMenu = true;
      } else {
        _items = generateFeedRoutes(_items, feed, Context);
      }
    }

    if (
      currentRoute.name === "stream" &&
      getStreamEntityId("stream", location.pathname)
    ) {
      _items = generateStreamRoutes(
        _items,
        getStreamEntityId("stream", location.pathname)
      );
    }

    const { exactRoute } = useCurrentRoute(location as any, RoutesConfig);

    if (
      exactRoute &&
      ["account_change_subscription"].includes(exactRoute.name)
    ) {
      const _page = query.get("page");
      _items = [];
      currentRoute.skipSubMenu = true;
      setBackButtonConfig({
        label: "Back",
        target: _page === "2" ? "account/subscription?page=1" : "account/home",
      });
    } else {
      setBackButtonConfig(defaultBackButtonConfig);
    }

    setMenuItems(_items);
  };

  // Hide menu on these route names
  if (
    (
      [
        "dashboard",
        "slider",
        "slider_preview",
        "redirection",
        ...AuthRoutNames,
      ] as RoutesNames[]
    ).includes(currentRoute.name)
  )
    return null;

  const requireTopMargin = !(["dashboard"] as RoutesNames[]).includes(
    currentRoute.name
  );

  const isAccountLicenceOverview = pathname.includes("licence-overview");

  console.log("pathname", pathname);
  console.log("isAccountLicenceOverview", isAccountLicenceOverview);

  const isTrial =
    account?.subscriptions?.[0]?.plan?.slug === "free-30-day-trial";
  console.log("isTrial", isTrial);
  console.log("account", account);
  const _licences = isTrial ? 5 : account?.subscriptions?.[0]?.licences ?? 0;

  const licencesFormatted = isTrial
    ? "5"
    : Math.abs(_licences) > 999
    ? Math.abs(_licences) / 1000 + "k"
    : String(Math.abs(_licences));

  return (
    <MenuStyledContainer
      requireTopMargin={requireTopMargin}
      stickyNotificationIsActive={stickyNotificationIsActive}
    >
      {account.type ? (
        <MenuStyledInner
          display="grid"
          gridTemplate={{ columns: `175px 1fr 37px 138px` }}
        >
          {isAccountLicenceOverview && (
            <>
              <BackButtonLicenceOverview
                skipUnderline={true}
                to={backButtonConfig.target}
                push={backButtonConfig.push}
              >
                <Icon name="arrowLeftLong" />
                <Block fontSize="smaller">{backButtonConfig.label}</Block>
              </BackButtonLicenceOverview>
              <LicenceOverviewInfoContainer>
                <Icon name="computerLight" />
                <Text color="white" fontWeight="bold">
                  <LicenceOverviewScreenCount>
                    {deviceData ? deviceData.length : 0} / {licencesFormatted}{" "}
                  </LicenceOverviewScreenCount>
                  LICENSES
                </Text>
              </LicenceOverviewInfoContainer>
            </>
          )}
          {!isAccountLicenceOverview && (
            <BackButton
              skipUnderline={true}
              to={backButtonConfig.target}
              push={backButtonConfig.push}
            >
              <Icon name="arrowLeftLong" />
              <Block fontSize="smaller">{backButtonConfig.label}</Block>
            </BackButton>
          )}
          {!isEmpty(menuItems) ? (
            <MenuItemsContainer>
              {map(menuItems, ({ name, label = "", path, exact }, index) => (
                <Block
                  key={name}
                  display="grid"
                  gridGap={10}
                  direction="column"
                  alignItems="center"
                >
                  <MenuItem to={excludeSlugFromPathname(path)} exact={exact}>
                    <Block fontSize="smaller">{t(label)}</Block>
                  </MenuItem>
                  {index < menuItems.length - 1 && (
                    <>
                      <MenuItemSeparator /> <span></span>
                    </>
                  )}
                </Block>
              ))}
            </MenuItemsContainer>
          ) : (
            <span></span>
          )}
          <span></span>
          {CtaConfig ? (
            <AppMenuCta
              elementHeightBase={false}
              buttonType={
                CtaConfig.submitted
                  ? "success"
                  : CtaConfig.error
                  ? "alert"
                  : "cta"
              }
              elementWidth="block"
              disablePointer={CtaConfig.submitting}
              disabled={CtaConfig.submitting}
              onClick={CtaConfig.action}
            >
              {CtaConfig.submitting ? (
                <Icon name="loading" strokeColor="white" />
              ) : CtaConfig.submitted ? (
                <Icon name="checkDark" color="white" />
              ) : (
                CtaConfig.label
              )}
            </AppMenuCta>
          ) : (
            <span></span>
          )}
        </MenuStyledInner>
      ) : (
        <Icon name="loading" strokeColor="white" />
      )}
    </MenuStyledContainer>
  );
};

export { AppMenu };
